import React, { FC } from 'react';

interface ToggleButtonProps {
    sidebar: boolean;
    showSidebar: () => void;
}

const ToggleButton: FC<ToggleButtonProps> = ({ sidebar, showSidebar }) => {
    return (
        <button onClick={showSidebar}>
            {sidebar ? 'Hide Sidebar' : 'Show Sidebar'}
        </button>
    );
};

export default ToggleButton;