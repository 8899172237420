import React, {FC} from "react";
import {Button, Space, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../config/AuthContext";

const SBConnect: FC = () => {
    const {authUser, isLoggedIn, setIsLoggedIn, setIsAdmin, setAuthUser} = useAuth();
    const navigate = useNavigate();

    const logIn = (e: any) => {
        e.preventDefault();
        navigate("/users/login");
    }

    const logOut = (e: any) => {
        e.preventDefault();
        setIsLoggedIn(false);
        setIsAdmin(false);
        setAuthUser({idt: -1, username: '', role: ''});
    }

    return (
        <Space direction={"horizontal"}>
            {isLoggedIn ? (
                <Button onClick={(e) => logOut(e)}>Déconnexion</Button>
            ) : (
                <Button onClick={(e) => logIn(e)}>Connexion</Button>
            )}
            {isLoggedIn ?
                (
                    <Typography className={"paragraph"}>
                        Bonjour et bienvenue {authUser.username}
                    </Typography>
                ) :
                (<></>)
            }
        </Space>
    );
}

export default SBConnect;