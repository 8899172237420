export interface IMatch {
    Idt: number;
    Num: number;
    Hre: string;
    Stade_Nom: string;
    Stade_Capacite: number;
    Ville_Nom: string;
    Fnt_cod: string;
    Dte: string;
    DteHre: Date;
    MatchTeam_Idt_Dom: number;
    team_idt_Dom: number;
    Team_Clr_Dom: string;
    Nom_Dom: string;
    Score_Dom: number;
    MatchTeam_Idt_Ext: number;
    team_idt_Ext: number;
    Team_Clr_Ext: string;
    Nom_Ext: string;
    Score_Ext: number;
    Grp_Cod: string;
    Journee_Lib: string;
    BetScore_Dom?: number;
    BetScore_Ext?: number;
}

export const HasMatchStarted = (match: IMatch): boolean => {
    const started: boolean = match.DteHre.toString() < new Date().toISOString();
    // console.log(`${match.Num} => ${started} ${match.DteHre.toString()} versus ${new Date().toISOString()}`);
    return started;
};

export const HasMatchResults = (match: IMatch): boolean => {
    const result: boolean = (match.Score_Dom ?? -1) !== -1 && (match.Score_Ext ?? -1) !== -1;
    // console.log(`Score Dom ${match.Score_Dom} - Score Ext ${match.Score_Ext} - result = ${result}`);
    return result;
};