import React, {useEffect, useState} from "react";
import AxiosServices from "../Services/axiosServices";
import CurrentData from "../config/CurrentData";
import {IMatchDays} from "../Interfaces/IMatchDays";
import {Button, Flex, Segmented} from "antd";
import "./MatchDays.css"
import {IMatch} from "../Interfaces/IMatch";
import {useGlobal} from "../config/GlobalContext";

const MatchDays = () => {

    useEffect(() => {
        AxiosServices.getMatchDays(setMatchDays, CurrentData.CmpEdt_Idt)
            .then()
            .catch(err => {
                console.log(err);
            });
    }, [])

    const [matchDays, setMatchDays] = useState<IMatchDays[]>([]);
    const [value, setValue] = useState<string | number>('*');
    const [selectedMatchDays, setSelectedMatchDays] = useState<string[]>([]);

    const {filteredMatches, setFilteredMatches} = useGlobal();

    const handleSegmentedChange = (val: string | number) => {
        setValue(val);
        if (val === '*') {
            setSelectedMatchDays([]);
            setFilteredMatches(""); // Show all matches
        } else {
            const selectedDay = matchDays.find(day => day.Lib === val);
            if (selectedDay) {
                const matchDayLibs = selectedDay.MatchDay.map(md => md.Lib);
                setSelectedMatchDays(matchDayLibs);
                filterMatches(matchDayLibs);
                if (matchDayLibs.length > 0) {
                    setFilteredMatches(matchDayLibs[0]);
                }
            } else {
                setSelectedMatchDays([]);
                setFilteredMatches("");
            }
        }
    }

    const handleSubSegmentedChange = (val: string | number) => {
        console.log('val', val);
        setFilteredMatches(val.toString());
    }

    const filterMatches = (matchDayLibs: string[]) => {
        AxiosServices.getMatches((matches: IMatch[]) => {
            const filtered = matches.filter(match => matchDayLibs.includes(match.Journee_Lib));
            if (filtered.length > 0)
                setFilteredMatches(filtered[0]?.Journee_Lib);
        }, CurrentData.CmpEdt_Idt).then(r =>
            console.log('Filtered matches : ', r)
        ).catch(err => {
            console.error(err);
        });
    }

    const matchDaysLibArray = matchDays.map(day => day.Lib);
    matchDaysLibArray.unshift('*');
    // console.log('matchDaysLibArray : ', matchDaysLibArray)

    const segmentedStyle = {
        backgroundColor: '#203923',
        color: 'wheat',
        borderColor: 'wheat',
        itemSelectedBg: 'wheat',
        itemSelectedColor: 'red'
    };

    return (
        <div className={"matchdays"}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 'small'}}>
                <Segmented
                    options={matchDaysLibArray}
                    value={value}
                    onChange={handleSegmentedChange}
                    style={segmentedStyle}
                />
                <Segmented
                    options={selectedMatchDays}
                    style={segmentedStyle}
                    value={filteredMatches}
                    onChange={handleSubSegmentedChange}
                />
            </div>
        </div>);
}


export default MatchDays;