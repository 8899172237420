import React, {FC} from "react";
import {useAuth} from "../../config/AuthContext";
import appConfig from "../../config";
import {Trans} from "react-i18next";

const SBFooter: FC = () => {

    const {setAuthUser, setIsLoggedIn, isLoggedIn, isAdmin} = useAuth();

    return (
        <span style={{ paddingRight: "1rem" }}>
            {isLoggedIn && isAdmin ? (<>Backend {appConfig.env} - API {appConfig.apiURL} - </>) : (
                <></>)
            }
            <Trans i18nKey={"Copyright"}/> - {process.env.REACT_APP_NAME} - {process.env.REACT_APP_VERSION}
        </span>
    )
}

export default SBFooter;