import React from "react";
import MatchDays from "./MatchDays";
import CmpEdt from "./CmpEdt";
import CustomInputNumber from "./CustomInputNumber";

const Development = () => {

    const [value, setValue] = React.useState<number>(3);

    function changeValue(value: number | null) {
        setValue(value ?? 0);
    }

    return (
        <>
            {/*<MatchDays/>*/}
            <CustomInputNumber value={value} max={12} min={0} onChange={changeValue}/>
        </>
    );
}

export default Development;