import {Context, createContext, ReactNode, useContext, useState} from "react";
import {IGrpCmpEdt} from "../Interfaces/IGrpCmpEdt";

type TValue = {
    showOld: boolean,
    setShowOld: (value: boolean) => void,
    groups: IGrpCmpEdt[],
    setGroups: (value: IGrpCmpEdt[]) => void,
    selectedMatchDays: string[],
    setSelectedMatchDays: (value: string[]) => void,
    filteredMatches: string,
    setFilteredMatches: (value: string) => void,
}

const DValue: TValue = {
    showOld: false,
    setShowOld: () => {},
    groups: [],
    setGroups: () => {},
    selectedMatchDays: [],
    setSelectedMatchDays: () => {},
    filteredMatches: "",
    setFilteredMatches: () => {},
}

const GlobalContext: Context<TValue> = createContext(DValue);

export function useGlobal(): TValue {
    return useContext(GlobalContext);
}

export function GlobalProvider({children}: { children: ReactNode }) {
    const [showOld, setShowOld] = useState(false); // Par défaut, on ne montre pas les anciens matchs
    const [groups, setGroups] = useState<IGrpCmpEdt[]>([]);
    const [selectedMatchDays, setSelectedMatchDays] = useState<string[]>([]);
    const [filteredMatches, setFilteredMatches] = useState<string>("");

    const value: TValue = {showOld, setShowOld, groups, setGroups, selectedMatchDays, setSelectedMatchDays, filteredMatches, setFilteredMatches};

    return (
        <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
    )
}

// export default GlobalContext;