import React from "react";

export const layoutStyle = {
    overflow: 'hidden',
    width: '100vw',
    height: '100vh',
};

export const footerStyle: React.CSSProperties = {
    textAlign: 'right',
    color: '#fff',
    backgroundColor: '#203923',
    maxWidth: '100%',
    maxHeight: '1.2rem',
    height: '1.2rem',
    padding: '0px',
};

export const siderStyle: React.CSSProperties = {
    textAlign: 'left',
    paddingTop: '5rem',
    paddingLeft: '10px',
    color: '#001122',
    backgroundColor: '#203923',
};

export const headerStyle: React.CSSProperties = {
    textAlign: 'left',
    color: 'wheat',
    height: '150px',
    paddingInline: 48,
    lineHeight: '64px',
    backgroundColor: '#203923',
    overflow: 'auto',
};

export const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    minHeight: 130,
    lineHeight: '2rem',
    color: '#fff',
    backgroundColor: '#203923',
};

