import {Flex, Space, Switch} from "antd";
import React, {FC} from "react";
import {Paragraph} from "../Typography";

interface SBHeaderProps {
    collapsed: boolean;
    setCollapsed: (collapsed: boolean) => void;
    text: string;
    AlignLeft?: boolean;
}


const SBHeaderSwitch: FC<SBHeaderProps> = ({ collapsed, setCollapsed, text, AlignLeft }) => {

    return (
        <>
            <Space direction={"horizontal"}>
                <Paragraph className={"paragraph"}>{AlignLeft ? text : ""}</Paragraph>
                <Switch size="small" checked={!collapsed} onChange={() => setCollapsed(!collapsed)}/>
                <Paragraph className={"paragraph"}>{!AlignLeft ? text : ""}</Paragraph>
            </Space>
        </>
    );

}

export default SBHeaderSwitch;