import React, {FC, useEffect, useState} from "react";
import {Flex, Layout} from "antd";
import Sidebar from "../Sidebar";
import SBHeader from "./SBHeader";
import SBFooter from "./SBFooter";
import {contentStyle, footerStyle, headerStyle, layoutStyle, siderStyle} from "./SBStyles";
import SBRoutes from "./SBRoutes";
import AxiosServices from "../../Services/axiosServices";
import CurrentData from "../../config/CurrentData";
import {useGlobal} from "../../config/GlobalContext";
const {Header, Content, Footer, Sider} = Layout;

const SBLayout: FC = () => {

    const {setGroups} = useGlobal()
    useEffect(() => {
        AxiosServices.getGroups(setGroups, CurrentData.CmpEdt_Idt)
            .then()
            .catch(err => {
                console.log(err);
            });

    }, [setGroups]);

    const [collapsed, setCollapsed] = useState(false);

    return (
        <Flex gap="middle" wrap>
            <Layout hasSider={true} style={layoutStyle}>
                {!collapsed &&
                <Sider width="15rem" style={siderStyle}>
                    <Sidebar/>
                </Sider>
                }
                <Layout>
                    <Header style={headerStyle}>
                        <SBHeader collapsed={collapsed} setCollapsed={setCollapsed}/>
                    </Header>
                    <Content style={contentStyle}>
                        <SBRoutes/>
                    </Content>
                    <Footer style={footerStyle}>
                        <SBFooter/>
                    </Footer>
                </Layout>
            </Layout>
        </Flex>
    );
}

export default SBLayout;