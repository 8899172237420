import React, {FC} from 'react';
import styled from 'styled-components';
import SidebarWrap from "./SidebarWrap";
import {SidebarData} from "../Data/SidebarData";
import Submenu from "./Submenu";
import ToggleButton from "./ToggleButton";

const SidebarNav = styled.div<{ sidebar: boolean }>`
    background-color: #162916;
    width: 15rem;
    height: 100vh;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
        // left: ${({sidebar}) => (sidebar ? "w-72" : "w-20 ")};
`;

const Sidebar: FC = () => {
    const [sidebar, setSidebar] = React.useState(true);
    const showSidebar = () => setSidebar(!sidebar);

    return (
        <>
            <ToggleButton sidebar={sidebar} showSidebar={showSidebar} />
            <SidebarNav sidebar={sidebar}>
                <SidebarWrap>
                    {SidebarData.map((item, index) => {
                        if (!item.development || (process.env.REACT_APP_ENV === 'local' && item.development)) {
                            return (
                                <Submenu item={item} key={index}/>
                            );
                        }
                    return null;
                    })}
                </SidebarWrap>
            </SidebarNav>
            {/*</IconContext.Provider>*/}
        </>
    );
}

export default Sidebar;