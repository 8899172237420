import {Route, Routes} from "react-router-dom";
import {IRoute, routes} from "../../Data/SidebarData";
import React from "react";
import {Splitter} from "antd";
import {useGlobal} from "../../config/GlobalContext";
import {IGrpCmpEdt} from "../../Interfaces/IGrpCmpEdt";
import {HiRectangleGroup} from "react-icons/hi2";

const SBRoutes = () => {

    const {groups} = useGlobal();
    return (
        <>
            <Splitter style={{ height: '100%', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <Splitter.Panel size={"10%"} max={"10%"} min={"10%"}>
                    {groups.map((group:IGrpCmpEdt) => {
                        return (
                                <p style={{color:group.clr, textAlign:"left", paddingLeft: "1rem"}} >{group.lib} <HiRectangleGroup/></p>
                        )
                    })
                    }
                </Splitter.Panel>
                <Splitter.Panel max={"80%"} min={"70%"}>
                    <Routes>
                        {routes.map((item: IRoute) => {
                            return <Route path={item.key} element={item.reactElement}></Route>
                        })}
                    </Routes>
                </Splitter.Panel>
            </Splitter>
        </>
    )
}

export default SBRoutes;