import React, {CSSProperties, FC} from "react";
import {Col, Flex, Row} from "antd";
import {useGlobal} from "../../config/GlobalContext";
import "./SBHeader.css";
import SBHeaderSwitch from "./SBHeaderSwitch";
import SBConnect from "./SBConnect";
import MatchDays from "../MatchDays";

interface SBHeaderProps {
    collapsed: boolean;
    setCollapsed: (collapsed: boolean) => void;
}

const SBHeader: FC<SBHeaderProps> = ({ collapsed, setCollapsed }) => {

    const {showOld, setShowOld} = useGlobal();
    const swapHideOld = (e: any) => {
        setShowOld(!showOld);
    }

    const boxStyle: CSSProperties = {
        width: '100%',
        padding: '1px',
    };

    const justify = 'space-between';
    const alignItems = 'flex-start';

    return (
        <>
            <Flex gap="middle" align="start">
                <Flex style={boxStyle} justify={justify} align={alignItems}>
                    <SBHeaderSwitch collapsed={collapsed} setCollapsed={setCollapsed}
                                    text={(collapsed ? "Montrer" : "Cacher") + " le menu"}

                    />
                    <SBConnect/>
                    <SBHeaderSwitch collapsed={!showOld} setCollapsed={swapHideOld}
                                    text={(showOld ? "Cacher" : "Montrer") + " les anciens matchs"}
                                    AlignLeft={true}
                    />
                </Flex>
            </Flex>
            <Row>
                <Col span={24}>
                    <MatchDays/>
                </Col>
            </Row>
        </>
    )
}

export default SBHeader;