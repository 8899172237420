import React, {FC} from "react";
import SaisieScores from "./SaisieScores";
import {Col, Flex, Row} from "antd";
import ShowResult from "./ShowResult";
import {IMatch} from "../../Interfaces/IMatch";
import {useAuth} from "../../config/AuthContext";

type Props = {
    match: IMatch
};
const Scores: FC<Props> = (props: Props) => {

    const {isLoggedIn} = useAuth();

    return (
        <div style={{width: "100%", alignItems: "center"}}>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <ShowResult disable={true}
                        // isMatchStarted={props.match && props.match.HasMatchStarted && props.match.HasMatchStarted()}
                                match={props.match}/>
                </Col>
                {isLoggedIn ?
                    <Col span={24}>
                        <SaisieScores disable={false}
                            // isMatchStarted={props.match && props.match.HasMatchStarted && props.match.HasMatchStarted()}
                                      testMatchStarted={true}
                                      match={props.match}/>
                    </Col>
                    : <></>
                }
            </Row>
            {/*<Flex gap={"large"} vertical>*/}
            {/*</Flex>*/}
        </div>
    );
}

export default Scores;