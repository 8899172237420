import {Box, styled, BoxProps} from "@mui/material";
import clsx from "clsx";
import {FC} from "react";

interface StyledBoxProps extends BoxProps {
    ellipsis?: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>(({ellipsis}) => ({
    textTransform: "none",
    ...(ellipsis && {whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"})
}));

interface HeadingProps extends BoxProps {
    ellipsis?: boolean;
    className?: string;
}

export const H1: FC<HeadingProps> = ({children, className, ellipsis, ...props}) => {
    return (
        <StyledBox
            mb={0}
            mt={0}
            component="h1"
            fontSize="28px"
            fontWeight="500"
            lineHeight="1.5"
            ellipsis={ellipsis}
            className={clsx({[className || ""]: true})}
            {...props}>
            {children}
        </StyledBox>
    );
};

export const H2: FC<HeadingProps> = ({children, className, ellipsis, ...props}) => {
    return (
        <StyledBox
            mb={0}
            mt={0}
            component="h2"
            fontSize="24px"
            fontWeight="500"
            lineHeight="1.5"
            ellipsis={ellipsis}
            className={clsx({[className || ""]: true})}
            {...props}>
            {children}
        </StyledBox>
    );
};

export const H3: FC<HeadingProps> = ({children, className, ellipsis, ...props}) => {
    return (
        <StyledBox
            mb={0}
            mt={0}
            component="h3"
            fontSize="18px"
            fontWeight="500"
            lineHeight="1.5"
            ellipsis={ellipsis}
            className={clsx({[className || ""]: true})}
            {...props}>
            {children}
        </StyledBox>
    );
};

export const H4: FC<HeadingProps> = ({children, className, ellipsis, ...props}) => {
    return (
        <StyledBox
            mb={0}
            mt={0}
            component="h4"
            fontSize="16px"
            fontWeight="500"
            lineHeight="1.5"
            ellipsis={ellipsis}
            className={clsx({[className || ""]: true})}
            {...props}>
            {children}
        </StyledBox>
    );
};

export const H5: FC<HeadingProps> = ({children, className, ellipsis, ...props}) => {
    return (
        <StyledBox
            mb={0}
            mt={0}
            component="h5"
            fontSize="12px"
            fontWeight="500"
            lineHeight="1.5"
            ellipsis={ellipsis}
            className={clsx({[className || ""]: true})}
            {...props}>
            {children}
        </StyledBox>
    );
};

export const H6: FC<HeadingProps> = ({children, className, ellipsis, ...props}) => {
    return (
        <StyledBox
            mb={0}
            mt={0}
            component="h6"
            fontSize="13px"
            fontWeight="500"
            lineHeight="1.5"
            ellipsis={ellipsis}
            className={clsx({[className || ""]: true})}
            {...props}>
            {children}
        </StyledBox>
    );
};

export const Paragraph: FC<HeadingProps> = ({children, className, ellipsis, ...props}) => {
    return (
        <StyledBox
            mb={0}
            mt={0}
            component="p"
            fontSize="12px"
            ellipsis={ellipsis}
            className={clsx({[className || ""]: true})}
            {...props}>
            {children}
        </StyledBox>
    );
};

export const Small: FC<HeadingProps> = ({children, className, ellipsis, ...props}) => {
    return (
        <StyledBox
            fontSize="12px"
            fontWeight="500"
            lineHeight="1.5"
            component="small"
            ellipsis={ellipsis}
            className={clsx({[className || ""]: true})}
            {...props}>
            {children}
        </StyledBox>
    );
};

export const Span: FC<HeadingProps> = ({children, className, ellipsis, ...props}) => {
    return (
        <StyledBox
            component="span"
            lineHeight="1.5"
            ellipsis={ellipsis}
            className={clsx({[className || ""]: true})}
            {...props}>
            {children}
        </StyledBox>
    );
};

export const Tiny: FC<HeadingProps> = ({children, className, ellipsis, ...props}) => {
    return (
        <StyledBox
            fontSize="10px"
            lineHeight="1.5"
            component="small"
            ellipsis={ellipsis}
            className={clsx({[className || ""]: true})}
            {...props}>
            {children}
        </StyledBox>
    );
};
