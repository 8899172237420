import React, {useContext, useState} from 'react';
import {IUser} from "../Interfaces/IUser";
import AxiosServices from "../Services/axiosServices";
import {useNavigate} from "react-router-dom";
import {setAuthToken} from "../Services/axiosConfig";

const DUser: IUser = {
    idt: -1
}

type TValue = {
    authUser: IUser,
    setAuthUser: (user: IUser) => void,
    isLoggedIn: boolean,
    setIsLoggedIn: (key: boolean) => void,
    isAdmin: boolean,
    setIsAdmin: (key: boolean) => void,
    token: string,
    setToken: (ket: string) => void,
    login: ({}) => void,
    logout: () => void,
}
const DValue: TValue = {
    authUser: DUser,
    setAuthUser: (user: IUser) => {
    },
    isLoggedIn: false,
    setIsLoggedIn: () => {
    },
    isAdmin: false,
    setIsAdmin: () => {
    },
    token: '',
    setToken: () => {
    },
    login: ({}) => {
    },
    logout: () => {
    },
}

const AuthContext = React.createContext(DValue);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}: { children: React.ReactNode }) {
    const [authUser, setAuthUser] = useState(DUser);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [token, setToken] = useState('');
    const navigate = useNavigate();

    const login = async (props: {}) => {
        await AxiosServices.AccountLogin(props)
            .then((response) => {
                const data = response.data;
                const token = data.token;
                if (!token) {
                    alert('Unable to login. Please try after some time.');
                    return;
                }
                //set token to axios common header
                setAuthToken(token);
                setAuthUser(data.user);
                const user: IUser = data.user;
                setIsAdmin(user.role === "ADMIN");
                setIsLoggedIn(true);
                navigate('/matchs')
            })
            .catch((err) => {
                // alert('Unable to login. Please try after some time.');
                alert(`${err.code} - ${err.message}`);
            });

    }
    const logout = async () => {
    }
    const value: TValue = {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn,
        isAdmin,
        setIsAdmin,
        token,
        setToken,
        login,
        logout
    };

    // useEffect(() => {
    //     const subscribe = authService.subscribe((u: IUser) => {
    //         if (u) {
    //             setIsLoggedIn(true);
    //             setAuthUser(u);
    //         } else {
    //             setIsLoggedIn(false);
    //             setAuthUser(DUser);
    //         }
    //     });
    //     return subscribe;
    // });

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    )
}

// export default AuthContext;

