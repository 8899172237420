import React from 'react';
import { InputNumber, Button } from 'antd';
import './CustomInputNumber.css';

interface CustomInputNumberProps {
    value: number;
    onChange: (value: number | null) => void;
    min?: number;
    max?: number;
}

const CustomInputNumber: React.FC<CustomInputNumberProps> = ({ value, onChange, min, max }) => {
    const increment = () => {
        if (max === undefined || value < max) {
            onChange(value + 1);
        }
    };

    const decrement = () => {
        if (min === undefined || value > min) {
            onChange(value - 1);
        }
    };

    return (
        <div className="custom-input-number">
            <Button onClick={increment} className="up-button">
                +
            </Button>
            <InputNumber
                min={min}
                max={max}
                value={value}
                onChange={onChange}
                changeOnWheel={true}
                className="input-number"
            />
            <Button onClick={decrement} className="down-button">
                -
            </Button>
        </div>
    );
};

export default CustomInputNumber;
