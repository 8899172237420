import {Card, Col, Row} from 'antd';
import React from 'react';
import './Match.css'
import './Team/Team.css';
import {DomExt, ITeam} from "./Team/ITeam";
import Team from "./Team/Team";
import Scores from "./Scores/Scores";
import {HasMatchResults, HasMatchStarted, IMatch} from "../Interfaces/IMatch";
import {useGlobal} from "../config/GlobalContext";
import {IUserBets} from "../Interfaces/IUserBets";
import {MdOutlineStadium} from "react-icons/md";
import {LiaMapSignsSolid} from "react-icons/lia";

interface ThisProps {
    match: IMatch,
    userBet?: IUserBets;
}

const MatchComponent: React.FC<ThisProps> = ({match, userBet}) => {

    const teamDom: ITeam = {
        Idt: match.team_idt_Dom,
        Nom: match.Nom_Dom,
        DomExt: DomExt.DOM,
        Color: match.Team_Clr_Dom,
    }
    const teamExt: ITeam = {
        Idt: match.team_idt_Ext,
        Nom: match.Nom_Ext,
        DomExt: DomExt.EXT,
        Color: match.Team_Clr_Ext
    }

    const {showOld} = useGlobal();
    const group = (match.Grp_Cod) && `Groupe ${match.Grp_Cod}, ` || '';

    match.BetScore_Dom = userBet?.matchteam_idt === match.MatchTeam_Idt_Dom ? userBet.score : undefined;
    match.BetScore_Ext = userBet?.matchteam_idt === match.MatchTeam_Idt_Ext ? userBet.score : undefined;

    return (
        (showOld || !(HasMatchResults(match) && HasMatchStarted(match)) ?
            <div className="MatchCard">
                <Card className={"MatchCard"}
                      title={<span>Match n° {match.Num}, le {match.Dte} à {match.Hre}<br/>
                          {match.Journee_Lib}<br/>
                          <MdOutlineStadium /> {group}{match.Stade_Nom} | {match.Ville_Nom} <LiaMapSignsSolid /></span>}
                      bordered={false}>
                    {/*<Space direction={"horizontal"}>*/}
                    <Row>
                        <Col span={8}>
                            <Team team={teamDom}/>
                        </Col>
                        <Col span={8}>
                            <Scores match={match}/>
                        </Col>
                        <Col span={8}>
                            <Team team={teamExt}/>
                        </Col>
                    </Row>
                    {/*</Space>*/}
                </Card>
            </div> :
            <></>)

    );

}
export default MatchComponent;
