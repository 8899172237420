import {SidebarItem} from '../models/SidebarItem';
import React from "react";
import Matchs from "../components/Matchs";
import {BiHome} from "react-icons/bi";
import {TbCircleLetterA, TbSoccerField} from "react-icons/tb";
import {FaCog, FaDev, FaRegMinusSquare, FaRegPlusSquare} from "react-icons/fa";
import {Configurations, Results, Standings, UsersResults} from "../pages/Pages";
import {GrAchievement, GrUser, GrWorkshop} from "react-icons/gr";
import {FaRankingStar} from "react-icons/fa6";
import About from "../components/About";
import {GiRank3} from "react-icons/gi";
import Groups from "../components/Groups";
import EmailVerify from "../components/Users/EmailVerify";
import Login from "../components/Users/Login-001";
import Classement from "../components/Users/Classement";
import UserRegister from "../components/Users/UserRegister";
import Development from "../components/Development";

export const SidebarData: SidebarItem[] = [
    {
        title: '',
        path: '/',
        element: <Matchs/>,
        icon: <BiHome/>,
    },
    {
        title: 'Utilisateurs',
        path: '/users',
        element: <Matchs/>,
        icon: <GrUser/>,
        iconClosed: <FaRegPlusSquare/>,
        iconOpened: <FaRegMinusSquare/>,
        subnav: [
            {
                title: 'Connexion',
                path: '/users/login',
                element: <Login/>,
                icon: <GrWorkshop/>,
            },
            {
                title: 'Inscription',
                path: '/users/register',
                element: <UserRegister/>,
                icon: <GrWorkshop/>,
            },
            {
                title: 'Résultats',
                path: '/users/results',
                element: <UsersResults/>,
                icon: <GrWorkshop/>,
            },
            {
                title: 'Classements',
                path: '/users/standings',
                element: <Classement/>,
                icon: <GiRank3/>,
            }
        ]
    },
    {
        title: 'Matchs',
        path: '/matchs',
        element: <Matchs/>,
        icon: <TbSoccerField/>,
        iconClosed: <FaRegPlusSquare/>,
        iconOpened: <FaRegMinusSquare/>,
        subnav: [
            {
                title: 'Résultats',
                path: '/matchs/results',
                element: <Results/>,
                icon: <GrAchievement/>
            },
            {
                title: 'Classements',
                path: '/matchs/standings',
                element: <Standings/>,
                icon: <FaRankingStar/>,
            }
        ]
    },
    {
        title: 'Configurations',
        path: '/configurations',
        element: <Configurations/>,
        icon: <FaCog/>,
        subnav: [
            {
                title: 'Groupes',
                path: '/configuration/groups',
                element: <Groups/>,
                icon: <GrAchievement/>,
            },
        ],
    },
    {
        title: 'A propos',
        path: '/about',
        element: <About/>,
        icon: <TbCircleLetterA/>,
    },
    {
        title: 'Developpement',
        path: '/development',
        element: <Development/>,
        icon: <FaDev/>,
        development: true
    }
];

export interface IRoute {
    key?: string;
    reactElement?: React.ReactElement;
}

function extractRoutes(menuItems: SidebarItem[]): IRoute[] {
    const elements: IRoute[] = [];

    for (const menuItem of menuItems) {
        const newElement: IRoute = {
            key: menuItem.path,
            reactElement: menuItem.element
        }
        elements.push(newElement);

        if (menuItem.subnav) {
            const childElements = extractRoutes(menuItem.subnav);
            elements.push(...childElements);
        }
    }

    return elements;
}

const fixedRoutes: IRoute[] = [
    {
        key: '/user/EmailVerify?',
        reactElement: <EmailVerify/>
    }
]

const routesFromMenuItems: IRoute[] = extractRoutes(SidebarData);
console.log(routesFromMenuItems);
export const routes: IRoute[] = [...fixedRoutes, ...routesFromMenuItems];