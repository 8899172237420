import React, {FC} from 'react';
import './App.css';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import {AuthProvider, useAuth} from "./config/AuthContext";
import {GlobalProvider} from "./config/GlobalContext";
import SBLayout from "./components/Layout/SBLayout";

const App: FC = () => {

    //check jwt token
    const {token, setToken} = useAuth();
    if (token) {
        console.log(`App : ${token}`)
        setToken(token);
    }

    return (
        <I18nextProvider i18n={i18n}>
            <AuthProvider>
                <GlobalProvider>
                    <SBLayout/>
                </GlobalProvider>
            </AuthProvider>
        </I18nextProvider>
    );
}

export default App;
