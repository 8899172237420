import {Image, Space} from 'antd';
import {DomExt, ITeam} from "./ITeam";
import './Team.css'
import {FC} from "react";
import {Paragraph} from "../Typography";
import {HiRectangleGroup} from "react-icons/hi2";

interface ThisProps {
    team: ITeam;
}

const Team: FC<ThisProps> = ({team}) => {

    const imageStyle = {
        width: '200px',
        height: '200px',
        objectFit: 'cover',
    };

    return (
        <Space direction={"vertical"} size={1}>
            <Space direction={"horizontal"} size={1}>
                <Paragraph style={{color: team.Color || "black", fontSize: "2em"}}
                           hidden={team.DomExt === DomExt.DOM}><HiRectangleGroup/></Paragraph>
                <Paragraph className="Team">{team.Nom}</Paragraph>
                <Paragraph style={{color: team.Color || "black", fontSize: "2em"}}
                           hidden={team.DomExt === DomExt.EXT}><HiRectangleGroup/></Paragraph>
            </Space>
            <Image className="image" src={`/images/${team.Idt}.png`} preview={false}/>
        </Space>
    );
}

export default Team;